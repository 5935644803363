<template>
  <div>
    <section class="page-title text-center">
      <div class="container">
        <div class="content-box">
          <h1>{{ body.name }}</h1>
        </div>
      </div>
    </section>

    <!--SECTION START-->
    <section
      style="background-color: white;padding-top: 36px;
padding-bottom: 150px;"
    >
      <div class="container">
        <div class=" row">
          <img
            :src="$baseUploadURL + body.image"
            alt="Image Blog"
            class="img-responsive col-md-6"
          />
          <div class=" col-md-6" v-html="body.userNotes"></div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
export default {
  data() {
    return {
      body: {
        name: null,
        userNotes: null,
        image: null,
      },
    };
  },

  created() {
    const id = this.$route.params.id;
    if (id) {
      this.$http.get(`admins/${id}`).then(
        (res) => {
          if (res.data) {
            this.body = res.data.data;
          }
        },
        (err) => {
          console.log(err);
        },
      );
    }
  },head: {
    title: {
      inner: 'Specialist Massage',
    },
    // Meta tags
    meta: [
      { name: 'application-name', content: 'Kleopatra Massage & Beauty' },
      {
        name: 'description',
        content: 'Kleopatra Massage & Beauty',
      }, // id to replace intead of create element
      // ...
      // Twitter
      { name: 'twitter:title', content: 'Kleopatra Massage & Beauty' },
      // with shorthand
      {
        n: 'twitter:description',
        c: 'Kleopatra Massage & Beauty',
      },
      // ...
      // Google+ / Schema.org
      { itemprop: 'name', content: 'Kleopatra Massage & Beauty' },
      { itemprop: 'description', content: 'Kleopatra Massage & Beauty' },
      // ...
      // Facebook / Open Graph
      // { property: 'fb:app_id', content: '123456789' },
      { property: 'og:title', content: 'Kleopatra Massage & Beauty' },
      // with shorthand
      {
        p: 'og:image',
        c: 'https://kleopatramassage-beauty.com/image/logo.png',
      },
      // ...
    ],
  },
};
</script>

<style></style>
